import { formatError } from "../utils/helpers";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import BlockButton from "../components/BlockButton";
import { loginUser } from "../state/user";
import styles from "./css/login.module.scss";

const UserLoginPassword = ({ redirectTo }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <Formik
      initialValues={{
        username: "",
        password: "",
      }}
      onSubmit={async (values, actions) => {
        try {
          await dispatch(
            loginUser(
              {
                username: values.username,
                password: values.password,
              },
              redirectTo
            )
          );
        } catch (err) {
          actions.setFieldError("username", formatError(err));
          console.log(err);
        }
      }}
      render={({ isSubmitting }) => (
        <Form className={styles.form}>
          <Field
            type="username"
            name="username"
            placeholder="Email"
            className={styles.input}
          />

          <ErrorMessage
            name="username"
            component="div"
            style={{ textAlign: "center" }}
          />

          <Field
            type="password"
            name="password"
            placeholder="Password"
            className={styles.input}
          />

          <BlockButton htmlType="submit" loading={isSubmitting}>
            {t("login.submit")}
          </BlockButton>
        </Form>
      )}
    />
  );
};

export default UserLoginPassword;
