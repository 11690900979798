import Button from "antd/lib/button";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import Space from "antd/lib/space";
import { navigate } from "gatsby";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import LogoSection from "../components/LogoSection";
import ResponsiveTitle from "../components/ResponsiveTitle";
import UserLoginPassword from "./UserLoginPassword";
import UserLoginPasswordless from "./UserLoginPasswordless";

const LoginMethods = {
  // SMS: "sms",
  PASSWORD: "password",
};
const Login = ({ location }) => {
  const isDesktop = useMediaQuery({ minWidth: 1224 });
  const { t } = useTranslation();
  const redirectTo = location.state ? location.state.from : undefined;
  const [loginMethod, setLoginMethod] = useState(LoginMethods.PASSWORD);
  return (
    <Row justify="center">
      {isDesktop && (
        <Col span={12}>
          <LogoSection />
        </Col>
      )}
      <Col span={isDesktop ? 12 : 24}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <ResponsiveTitle>{t("login.logIn")}</ResponsiveTitle>
          <UserLoginPassword redirectTo={redirectTo} />
          <Row justify="center">
            <Button type="link" onClick={() => navigate("/app/signup")}>
              {t("login.register")}
            </Button>
          </Row>
        </Space>
      </Col>
    </Row>
  );
};
export default Login;
