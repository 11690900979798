import { Auth } from "aws-amplify";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import BlockButton from "../components/BlockButton";
import { loginUserPasswordlessly } from "../state/user";
import styles from "./css/login.module.scss";

const UserLoginPasswordless = ({ redirectTo }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [tempUser, setTempUser] = useState();
  const hasCustomChallenge =
    tempUser && tempUser.challengeName === "CUSTOM_CHALLENGE";

  console.log(tempUser);

  return (
    <Formik
      initialValues={{
        phoneNumber: "",
      }}
      onSubmit={async (values, actions) => {
        try {
          if (hasCustomChallenge) {
            await dispatch(
              loginUserPasswordlessly(tempUser, values.code, redirectTo)
            );
          } else {
            const data = await Auth.signIn(values.phoneNumber);
            setTempUser(data);
          }
        } catch (err) {
          actions.setFieldError("errormessage", err.message);
          console.log(err);
        }
      }}
      render={() => (
        <Form className={styles.form}>
          <Field
            type="phone"
            name="phoneNumber"
            placeholder="Phone Number (+61)"
            className={styles.input}
          />
          {hasCustomChallenge && (
            <Field
              type="text"
              name="code"
              placeholder="Code"
              className={styles.input}
            />
          )}
          <ErrorMessage name="errormessage" style={{ textAlign: "center" }} />
          <BlockButton htmlType="submit">{t("login.getCode")}</BlockButton>
        </Form>
      )}
    />
  );
};

export default UserLoginPasswordless;
